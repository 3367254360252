import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Avatar from "../avatar";
import { Container, List } from "../blocks";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Container;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Avatar`}</h1>
    <Avatar size={256} mdxType="Avatar" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/avatar.svg"
        }}>{`Download SVG`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/avatar.png"
        }}>{`Download PNG`}</a></li>
    </ul>
    <p>{`My avatar originated in 2010, while I was working as a designer at Opower.
Each person on the design team illustrated a self-portrait that we would use to create a combined team portrait.
In the `}<a parentName="p" {...{
        "href": "https://dribbble.com/shots/19382-Jackson-a-opossum"
      }}>{`original version`}</a>{`, I was holding a opossum playing dead.
The opossum was sort of an unofficial mascot that we'd made up, and well, I'm originally from West Virginia.`}</p>
    <p>{`The avatar has seen a few revisions over time, and the current version is almost completely hand-coded and hosted on `}<a parentName="p" {...{
        "href": "https://github.com/ZvezdocheTag/avatar"
      }}>{`GitHub`}</a>{`.`}</p>
    <h2>{`Variations`}</h2>
    <p>{`Over the years, I've made a few variations for special occassions.`}</p>
    <List mdxType="List">
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://dribbble.com/shots/70366-Getting-in-the-spirit"
          }}><img parentName="a" {...{
              "src": "https://cdn.dribbble.com/users/2014/screenshots/70366/shot_1288047392.png",
              "alt": "Zombie avatar"
            }}></img></a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://dribbble.com/shots/84535-In-case-you-didn-t-notice"
          }}><img parentName="a" {...{
              "src": "https://cdn.dribbble.com/users/2014/screenshots/84535/shot_1291146895.png",
              "alt": "Movember avatar"
            }}></img></a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://dribbble.com/shots/3036929-Free-candy"
          }}><img parentName="a" {...{
              "src": "https://cdn.dribbble.com/users/2014/screenshots/3036929/balloonanimals.png",
              "alt": "Clown avatar"
            }}></img></a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://dribbble.com/shots/283105-Tis-the-Season"
          }}><img parentName="a" {...{
              "src": "https://cdn.dribbble.com/users/2014/screenshots/283105/tistheseason.png",
              "alt": "Skull avatar"
            }}></img></a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://dribbble.com/shots/751505-Ermah-gourd-Jack-O-Lantern"
          }}><img parentName="a" {...{
              "src": "https://cdn.dribbble.com/users/2014/screenshots/751505/jackolantern.png",
              "alt": "Jackolantern avatar"
            }}></img></a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://dribbble.com/shots/270423-Arg"
          }}><img parentName="a" {...{
              "src": "https://cdn.dribbble.com/users/2014/screenshots/270423/arg.png",
              "alt": "Pirate avatar"
            }}></img></a></li>
      </ul>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      